<template>
    <div>
        <h4 class="bold text-uppercase">Tài khoản</h4>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a
                    class="nav-link active"
                    @click="handleShowPanel('user')"
                    id="home-tab"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    >Cài đặt</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    @click="handleShowPanel('logs')"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                    >Nhật ký hoạt động</a
                >
            </li>
        </ul>
        <div class="tab-content mt-3" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div class="row mt-3">
                    <div class="col-md-8 col-12">
                        <form @submit.stop.prevent="updateInfo">
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label font-bold">Tên Tài Khoản</label>
                                <div class="col-sm-8">
                                    <input
                                        type="text"
                                        class="form-control input-light username-text"
                                        v-model="user_update.username"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="example-email" class="col-sm-4 col-form-label font-bold">Email</label>
                                <div class="col-sm-8">
                                    <input
                                        type="email"
                                        placeholder="Nhập vào email của bạn để nhận thông báo hoặc reset mật khẩu"
                                        class="form-control input-light"
                                        v-model="user_update.email"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="example-email" class="col-sm-4 col-form-label font-bold"
                                    >Facebook ID (Nhập Link Facebook của bạn)</label
                                >
                                <div class="col-sm-8">
                                    <input
                                        type="text"
                                        placeholder="Nhập vào fb id của bạn"
                                        class="form-control input-light"
                                        v-model="user_update.fbid"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label font-bold">Mật khẩu cũ</label>
                                <div class="col-sm-8">
                                    <input
                                        type="password"
                                        class="form-control input-light"
                                        v-model="user_update.old_password"
                                        placeholder="Để trống nếu chưa từng đặt mật khẩu"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label font-bold">Mật khẩu mới</label>
                                <div class="col-sm-8">
                                    <input
                                        type="password"
                                        value="password"
                                        class="form-control input-light"
                                        v-model="user_update.password"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label font-bold">Nhập lại mật khẩu mới</label>
                                <div class="col-sm-8">
                                    <input
                                        type="password"
                                        value="password"
                                        class="form-control input-light"
                                        v-model="user_update.password_confirm"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label font-bold">Họ và Tên</label>
                                <div class="col-sm-8">
                                    <input
                                        type="text"
                                        placeholder="Nhập họ và tên của bạn"
                                        class="form-control input-light"
                                        v-model="user_update.name"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label font-bold">Số Điện Thoại</label>
                                <div class="col-sm-8">
                                    <input
                                        type="text"
                                        placeholder="Nhập số điện thoại của bạn"
                                        class="form-control input-light"
                                        v-model="user_update.phone"
                                    />
                                    <button class="btn mt-3 form-control bold btn-dark-blue" type="submit">
                                        Lưu thông tin
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-md-4 col-12">
                        <div class="card card-profile py-5">
                            <div class="card-body">
                                <h6 class="mb-0 text-center">
                                    <a
                                        class="nav-link waves-effect waves-dark profile-pic"
                                        href=""
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <img
                                            :src="
                                                user.avatar_id
                                                    ? 'https://graph.facebook.com/' +
                                                      user.avatar_id +
                                                      '/picture?width=100&height=100'
                                                    : 'assets/images/users/avatar_fb.jpg'
                                            "
                                            width="100"
                                            class="img-avatars rounded-circle border"
                                            alt="user"
                                        />
                                    </a>
                                </h6>
                                <h6 class="mb-0 text-center bold font-19">
                                    {{ user.name ? user.name : user.username }}
                                </h6>
                                <h6 class="mb-0 text-center font-14">
                                    {{ user.role }}
                                </h6>
                                <h6 class="text-center bold font-19 pb-4">
                                    {{ user.coin | formatNumber }}
                                </h6>
                                <h6>
                                    Email: <span class="ml-4">{{ user.email ? user.email : "Chưa cập nhật" }}</span>
                                </h6>
                                <h6>
                                    Phone: <span class="ml-3">{{ user.phone ? user.phone : "Chưa cập nhật" }}</span>
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <!-- <div class="row">
                    <div class="col-md-4 col-12 mb-3" v-for="(log, index) in notifies" :key="index">
                        <div class="card card-white">
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col-7">
                                        <h5 class="bold mb-0 block-text green">{{ log.actions_html }}</h5>
                                    </div>
                                    <div class="col text-right">
                                        <h6 class="font-14">{{ log.created_at | timeAgo }}</h6>
                                    </div>
                                </div>
                                <h6 class="font-14 mb-0 block-text-2" :title="log.content">{{ log.content }}</h6>
                                <h6 class="mb-0 bold green">
                                    <span v-show="log.quantity"><i class="fab fa-telegram-plane mr-1"></i> {{ log.quantity | formatNumber }}</span> {{ log.tool ? log.tool.name : log.action }}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div> -->
                <userreport v-if="panel == 'logs'"></userreport>
            </div>
        </div>
    </div>
</template>
<script>
import { userUpdateInfo } from "../../api/user"
import { catchError } from "../../helpers"
import userreport from "./user-report"
export default {
    name: "user-manager",
    components: {
        userreport
    },
    data() {
        return {
            panel: "",
            user_update: {},
            notifies: []
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        isMobile() {
            return this.$store.state.app.isMobile
        },
        site() {
            return this.$store.state.site.site
        },
        reload() {
            return this.$store.state.app.reload
        }
    },
    created() {
        this.user_update = Object.assign({}, this.user)
    },
    methods: {
        updateInfo: async function() {
            // if (!confirm("Bạn có chắn muốn cập nhật thông tin tài khoản")) {
            //     return
            // }
            if (this.user_update.password !== "" && this.user_update.password !== this.user_update.password_confirm) {
                this.$toastr.error("Mật khẩu mới và mật khậu nhập lại không khớp")
                return
            }
            let postData = {
                id: this.user.id,
                old_password: ""
            }
            for (const key in this.user_update) {
                if (this.user_update.hasOwnProperty(key) && this.user[key]) {
                    if (this.user_update[key] !== this.user[key]) {
                        postData[key] = this.user_update[key]
                    }
                } else {
                    postData[key] = this.user_update[key]
                }
            }
            let data = await userUpdateInfo(this.user.id, postData)
            if (data.status === 200 && data.success) {
                this.$swal("Thành công", "Cập nhật thông tin thành công", "success")
                // await this.$store.dispatch("USER_GET_INFO")
                this.isDisabled = false
            } else {
                this.$swal("Lỗi", catchError(data), "error")
                this.isDisabled = false
            }
        },
        handleShowPanel: async function(panel) {
            this.panel = panel
        }
    }
}
</script>
